import { useTranslation } from "react-i18next";

const PromptsSuggestion = ({ data, showPrompts, setShowPrompts, handlePromptClick }) => {

    // console.log(data);
    const { t } = useTranslation();

    const language = localStorage.getItem('language') || 'en';

    return (
        <div className={`propmpt-suggestion-ct relative bottom-[0px] w-full px-3 bg-white pt-1 mt-0 transition ease-in-out duration-700 ${ showPrompts && data?.length > 0 ? 'que-popup-ct-shows' : 'que-popup-ct-hide' }`}>
            <div className="flex items-center justify-between mt-2 mb-[13px]">
                <p className={`mb-0 text-[16px] font-semibold text-${language == 'ar' ? 'right': 'left'}`}>{t('Try asking')}</p>
                <div className="cursor-pointer" onClick={() => setShowPrompts(false)}>
                    <img src="/assets/images/close-icon.svg" />
                </div>
            </div>
            <div className="max-h-[120px] overflow-auto">
                {data && data.map((item)=>
                    <div key={item.id} className="bg-[#ffffff] border border-[#e9e9e9] rounded-[5px] px-[10px] py-[7px] my-2 cursor-pointer" onClick={() => {handlePromptClick(item?.Question)}}>
                        <p className={`text-[13px] mb-0 text-${language == 'ar' ? 'right': 'left'}`}>{item?.Question}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PromptsSuggestion;