import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './services/i18n';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));

// let allowedDomains = process.env.REACT_APP_ALLOWED_DOMAINS?.split(',');

const Main = () => {

  const { i18n } = useTranslation();

  const ASSETS_URL = process.env.REACT_APP_ASSETS_URL;

  const [verified, setVerified] = useState(false);
  const [iframeDomain, setIframeDomain] = useState('');
  const [view, setView] = useState('narrow');
  const [domains, setDomains] = useState(null);
  const [domainFromIframeMessage, setDomainFromIframeMessage] = useState();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const botId = urlParams?.get("bot");
  const language = urlParams?.get('language');
  const color1 = urlParams?.get('primary');
  const defaultView = urlParams?.get('view');
  const domain = urlParams?.get('domain') ? urlParams?.get('domain') : "localhost:3000";

  const localLanguage = localStorage.getItem('language');
  // const language = "en";

  const handlePostMessage = (event) => {
    // console.log(event,domain,'event');
	  if(event.origin.includes(domain) || domain == 'localhost:3000') {
      setDomainFromIframeMessage(event?.data?.domain ? event?.data?.domain : domain);
    }

    if(event.origin.includes(domain) || domain == 'localhost:3000')
      if(event.data.view){
        setView(event.data.view)
      }
  };

  const fetchDomains = async () => {

    await axios({
      url: `${ASSETS_URL}/api/widget-domains`,
      method: 'GET'
    }).then((response) => {
      if(response.status == 200){

        const whitelistedDomains = response.data.data;

        setDomains(whitelistedDomains);

        for(let i = 0; i < whitelistedDomains.length; i++){
          if((whitelistedDomains[i].attributes.Domain == domain && whitelistedDomains[i].attributes.Domain == domainFromIframeMessage) || (whitelistedDomains[i].attributes.Domain == '*')){
            setIframeDomain(domain);
            setVerified(true);
          }
        }
      }

    }).catch((err) => {
      console.log("Error fetching domains ======>", err)
    })
  }

  useEffect(() => {
    if(domainFromIframeMessage) fetchDomains();
  }, [domainFromIframeMessage])

  useEffect(() => {

    setView(defaultView);

    window.addEventListener('message', handlePostMessage);
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };

  }, []);

  // useEffect(() => {

  //   document.dir = i18n.dir();

  // }, [i18n, i18n.language]);

  useEffect(() => {

    i18n.changeLanguage(localLanguage ? localLanguage : language ? language : "en");

    if(!localLanguage){
      localStorage.setItem('language', language ? language : 'en')
    }

  }, [language])

  ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);

  return (
    <React.StrictMode>
      <BrowserRouter>
        {/* for deployed site */}
        { verified && botId && botId !== "null" && <App iframeDomain={iframeDomain} botApiId={botId} view={view} primaryColor={color1 ? `#${color1}` : '#912d2a'}/> }

        {/* for local site */}
        {/* <App iframeDomain={'demo.noesis.dev'} botApiId='e7e22c8c_36cb_407b_a60d_8c7a34817280' view={view} primaryColor={color1 ? `#${color1}` : '#912d2a'} /> */}
      </BrowserRouter>
    </React.StrictMode>
  );
}

root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
